import React, { Fragment, Component, createContext } from "react";
import LandingImsakiyah from "./LandingImsakiyah";
import MainImsakiyah from './MainImsakiyah';
import MainLayout from "./MainLayout";
import axios from "axios";
import { encodedToken, URL } from "../utils/authAPI";
import { getTimePray, getPray, getFormattedDate, getNextPrayer } from '../utils/prayer';
import { toCapital } from "../utils/textManipulation";

export const RootContext = createContext();
const Provider = RootContext.Provider;

export default class ImsakiyahPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      provinsi: [],
      provSelect: { value: this.props.prov },
      kota: [],
      kotaSelected: [],
      jadwalImsakiyah: false,
      imsakiyah: {
        Imsak: "00:00:00",
        Subuh: "00:00:00",
        Terbit: "00:00:00",
        Duha: "00:00:00",
        Zuhur: "00:00:00",
        Asar: "00:00:00",
        Magrib: "00:00:00",
        Isya: "00:00:00"
      },
      date: new Date(),
      prayer: getPray(),
      nextPrayer: getTimePray(),
      timeNextPrayer: '',
      prov: this.props.prov,
      slug: this.props.slug
    }

    const d = new Date();
    this.date = new Date(d);
    this.day = this.date.getDate();
    this.day = '0' + this.day;
    this.day = this.day.slice(-2);
    this.month = ((this.date.getMonth() + 1) < 10 ? '0' : '') + (this.date.getMonth() + 1);
    this.month.slice(-2)
    const year = this.date.getFullYear();
    this.tgl = `${year}-${this.month}-${this.day}`;
  }

  componentDidMount() {
    this.getImsakiyahBySlug();
    axios({
      url: URL + `daerah/provinsi`,
      headers: {
        'Authorization': `Basic ${encodedToken}`
      }
    }).then(res => {
      const data = res.data;
      // Ubah key Object
      for (let i = 0; i < data.data.length; i++) {
        var temp = data.data[i].kodeProvinsi;
        data.data[i].value = temp;
        delete data.data[i].kodeProvinsi;

        var temp2 = data.data[i].namaProvinsi;
        data.data[i].name = temp2;
        delete data.data[i].namaProvinsi;
      }

      this.setState({ provinsi: data.data });
      data.data.forEach((a) => {
        if (a.value === this.state.prov) {
          this.setState({ provSelect: a })
        }
      });
    }).catch(error => {
      console.log(error);
    })

    this.getImsakiyahByKota();
  }

  getImsakiyahByKota = (provId = this.state.provSelect.value) => {
    axios({
      url: URL + `daerah/kabupaten/${provId}`,
      headers: {
        'Authorization': `Basic ${encodedToken}`
      }
    }).then(res => {
      const data = res.data;
      // Ubah key Object
      for (let i = 0; i < data.data.length; i++) {
        var temp = data.data[i].kodeKabupaten;
        data.data[i].value = temp;
        delete data.data[i].kodeKabupaten;

        var temp2 = data.data[i].namaKabupaten;
        data.data[i].name = temp2;
        delete data.data[i].namaKabupaten;
      }
      this.setState({ kota: data.data });

      data.data.forEach((a) => {
        if (a.SlugKabupaten === this.props.slug) {
          this.setState({ kotaSelected: a })
        }
      });
    }).catch(error => {
      console.log(error);
    })
  }

  getImsakiyahBySlug = (slug = this.state.slug) => {
    axios({
      url: URL + `jadwal/bulan/${this.month}/${this.date.getFullYear()}/${slug}`,
      headers: {
        'Authorization': `Basic ${encodedToken}`
      }
    }).then(res => {
      const jadwalImsakiyah = res.data.data.list_jadwal_obj;
      let prov = res.data.data.kodeProvinsi;
      let namaKabupaten = toCapital(res.data.data.namaKabupaten);
      document.title = `Jadwal Shalat dan Imsakiyah ${namaKabupaten.join(' ')} | LEUGHOK.com`;
      this.setState({ jadwalImsakiyah: jadwalImsakiyah });
      if (this.state.prov === '') {
        this.setState({ prov: prov });
      }
      for (let i = 0; i < jadwalImsakiyah.length; i++) {
        if (jadwalImsakiyah[i].Tanggal === this.tgl) {
          let data = this.state.prayer;
          data[0] = { name: "Subuh", time: getFormattedDate(`${jadwalImsakiyah[i].Subuh}`) };
          data[1] = { name: "Dzuhur", time: getFormattedDate(`${jadwalImsakiyah[i].Zuhur}`) };
          data[2] = { name: "Ashar", time: getFormattedDate(`${jadwalImsakiyah[i].Asar}`) };
          data[3] = { name: "Maghrib", time: getFormattedDate(`${jadwalImsakiyah[i].Magrib}`) };
          data[4] = { name: "Isya", time: getFormattedDate(`${jadwalImsakiyah[i].Isya}`) };
          let dataFromGetNextPrayer = getNextPrayer(data);
          this.setState({ nextPrayer: dataFromGetNextPrayer[0], timeNextPrayer: dataFromGetNextPrayer[1] });
          this.setState({ imsakiyah: jadwalImsakiyah[i], prayer: data });
        }
      }
    }).catch(error => {
      console.log(error);
    })
  }

  handleImsakiyahChange = (bulan, tahun) => {
    if (bulan < 10) {
      bulan = `0${bulan}`;
    }
    axios({
      url: URL + `jadwal/bulan/${bulan}/${tahun}/${this.state.slug}`,
      headers: {
        'Authorization': `Basic ${encodedToken}`
      }
    }).then(res => {
      const jadwalImsakiyah = res.data.data.list_jadwal_obj;
      this.setState({ jadwalImsakiyah: jadwalImsakiyah });
    });
  }

  handleNextPrayer = (nextPrayer, timeNextPrayer) => {
    this.setState({ nextPrayer: nextPrayer, timeNextPrayer: timeNextPrayer });
  }


  handleProvChange = (data) => {
    this.getImsakiyahByKota(data.value);
    this.setState({ provSelect: data });
  }

  handleKotaChange = (data, prov) => {
    this.getImsakiyahBySlug(data.SlugKabupaten);
    this.setState({ kotaSelected: data, provSelect: prov });
  }

  render() {
    return (
      <Provider value={
        {
          state: this.state,
          handleKotaChange: this.handleKotaChange,
          handleProvChange: this.handleProvChange,
          handleNextPrayer: this.handleNextPrayer,
          handleImsakiyahChange: this.handleImsakiyahChange,
          getImsakiyahBySlug: this.getImsakiyahBySlug,
        }
      }>
        <Fragment>
          <MainLayout active='imsakiyah'>
            <LandingImsakiyah time={this.state.timeNextPrayer}></LandingImsakiyah>
            <MainImsakiyah></MainImsakiyah>
          </MainLayout>
        </Fragment >
      </Provider >
    );
  }
}
