export const months = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];

//a simple date formatting function
export const dateFormat = (inputDate) => {
  //parse the input date
  const date = new Date(inputDate);

  //extract the parts of the date
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  // Get Day
  var weekDays = getWeekDays('id-ID', year, month, day);

  var format = `${weekDays}, ${day} ${months[month]} ${year}`;

  return format;
}

function getWeekDays(locale, year, month, day) {
  var baseDate = new Date(Date.UTC(year, month, day)); // just a Monday
  var weekDays = [];
  weekDays.push(baseDate.toLocaleDateString(locale, { weekday: 'long' }));
  baseDate.setDate(baseDate.getDate() + 1);
  return weekDays;
}
