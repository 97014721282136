import React from 'react'
import Alquran from '../component/Alquran'

export default function SuratLayout() {
  return (
    <>
      <div id="puasa-ramadhan" className='mt-10'>
        <h2 className="text-xl md:text-2xl mb-2 font-bold">Niat Puasa Ramadhan</h2>
        <p className='text-sm text-justify indent-8 mb-3'>
          Niat adalah salah satu rukun yang harus dilakukan ketika berpuasa, maka hukumnya adalah wajib. Dengan adanya niat, menunjukkan kejelasan dari puasa yang akan diamalkan.
        </p>
        <p className='text-sm text-justify indent-8 lg:indent-0 mb-3'>
          Niat puasa dilafalkan malam hari, sejak terbitnya matahari sampai terbitnya fajar. Berikut adalah lafal niat puasa Ramadhan:
        </p>
        <Alquran ayat="نَوَيْتُ صَوْمَ غَدٍ عَنْ أَدَاءِ فَرْضِ شَهْرِ رَمَضَانِ هذِهِ السَّنَةِ لِلهِ تَعَالَى" baca="Nawaitu shauma ghadin an’adai fardi syahri Ramadhana hadzihissanati lillâhi ta‘âlâ." arti="Saya berniat berpuasa esok hari untuk menunaikan fardhu di bulan Ramadhan tahun ini karena Allah Ta’ala" />
      </div>

      <div id="puasa-sk-1" className='mt-14'>
        <h2 className="text-xl md:text-2xl mb-2 font-bold">Niat Puasa Senin Kamis </h2>
        <p className='text-sm text-justify indent-8 mb-3'>
          Sama dengan puasa pada umumnya, niat untuk puasa senin kamis dilakukan pada malam hari, sejak terbitnya matahari sampai terbitnya fajar. Adapun niat untuk puasa senin kamis adalah sebagai berikut:
        </p>
        <h3 className='mt-4 mb-1 font-bold'>Niat Puasa Senin:</h3>
        <Alquran ayat="نَوَيْتُ صَوْمَ يَوْمِ الِاثْنَيْنِ لِلّٰهِ تَعَالَى" baca="Nawaitu shauma yaumil itsnaini lillâhi ta‘âlâ." arti="Aku berniat puasa sunah hari Senin karena Allah Ta'ala" />
        <h3 className='mt-4 mb-1 font-bold'>Niat Puasa Kamis:</h3>
        <Alquran ayat="نَوَيْتُ صَوْمَ يَوْمِ الخَمِيْسِ لِلّٰهِ تَعَالَى" baca="Nawaitu shauma yaumil khamîsi lillâhi Ta‘âlâ." arti="Aku berniat puasa sunah hari Kamis karena Allah Ta'ala" />
      </div>

      <div id="puasa-sk-2" className='mt-14'>
        <h2 className='text-xl md:text-2xl mb-2 font-bold'>Niat Puasa Senin Kamis Pada Siang Hari</h2>
        <p className='indent-8 text-sm text-justify mb-3'>
          Puasa Senin Kamis masuk dalam kategori puasa sunnah, maka ada keringanan jika lupa niatnya lupa dilakukan atau dilafalkan pada malam hari.
        </p>
        <p className="indent-8 text-sm text-justify">
          Jika niat puasa senin kamis lupa dilakukan atau dilafalkan pada malam hari, maka boleh dilakukan pada siang hari, yaitu dari pagi hari sampai sebelum waktu dzuhur,  dengan catatan tidak dilakukan hal-hal yang dapat membatalkan puasa seperti makan, minum dan lainnya. Berikut adalah niat puasa senin kamis jika dilafalkan pada siang hari.
        </p>
        <h3 className='mt-4 mb-1 font-bold'>Niat Puasa Senin Jika Dilafalkan pada Siang Hari:</h3>
        <Alquran ayat="نَوَيْتُ صَوْمَ هٰذَا اليَوْمِ عَنْ أَدَاءِ سُنَّةِ يَوْمِ الِاثْنَيْنِ لِلّٰهِ تَعَالَى" baca="Nawaitu shauma hâdzal yaumi ‘an adâ’i sunnati yaumil itsnaini lillâhi ta‘âlâ." arti="Aku berniat puasa sunah hari Senin ini karena Allah Ta'ala" />

        <h3 className='mt-4 mb-1 font-bold'>Niat puasa Kamis Jika Dilafalkan pada Siang Hari:</h3>
        <Alquran ayat="نَوَيْتُ صَوْمَ هٰذَا اليَوْمِ عَنْ أَدَاءِ سُنَّةِ يَوْمِ الخَمِيْسِ لِلّٰهِ تَعَالَى" baca="Nawaitu shauma hâdzal yaumi ‘an adâ’i sunnati yaumil khamîsi lillâhi ta‘âlâ." arti="Aku berniat puasa sunah hari Kamis ini karena Allah Ta'ala" />
      </div>

      <div id="buka-puasa" className='mt-14'>
        <h2 className='text-xl md:text-2xl mb-2 font-bold'>Niat Buka Puasa</h2>
        <p className='indent-8 text-sm text-justify mb-3'>
          Ketika matahari sudah terbenam dan sudah masuknya Maghrib, maka wajib bagi orang yang berpuasa untuk segera berbuka puasa.
        </p>
        <p className="text-sm text-justify mb-3">
          Berikut adalah doa niat untuk berbuka puasa:
        </p>
        <Alquran ayat="اَللّهُمَّ لَكَ صُمْتُ وَبِكَ آمَنْتُ وَعَلَى رِزْقِكَ أَفْطَرْتُ بِرَحْمَتِكَ يَا اَرْحَمَ الرَّحِمِيْنَ" baca="Allahuma laka shumtu wa bika amantu wa’ala rizqika afthartu. Birrahmatika yaa arhamar raahimiin." arti="Ya Allah, untuk-Mu saya berpuasa, dan kepadaMu saya beriman, dan dengan rezekiMu saya berbuka. Dengan rahmat-Mu Wahai Yang Maha Pengasih dan Penyayang" />
      </div>

      <div id="shalat-fardhu" className='mt-14'>
        <h2 className='text-xl md:text-2xl mb-2 font-bold'>Niat Shalat Fardhu atau Shalat Wajib</h2>
        <p className='indent-8 text-sm text-justify mb-3'>
          Berdasarkan firman Allah SWT, dalam al-Qur’an menyebutkan bahwa setiap muslim yang mukallaf wajib melaksanakan shalat lima waktu dalam sehari semalam.
        </p>
        <p className="indent-8 text-sm text-justify">
          Shalat lima waktu ini masing-masing dilakukan sesuai waktunya masing-masing saat subuh, dzuhur, ashar, maghrib dan isya. Masing-masing waktu shalat ini memiliki niatnya masing-masing yang berbeda, sebagai berikut:
        </p>
        <div id="list-shalat-fardhu">
          <h3 className='mt-4 mb-1 font-bold'>Niat Shalat Subuh</h3>
          <Alquran ayat="أُصَلِّى فَرْضَ الصُّبْح رَكَعتَيْنِ مُسْتَقْبِلَ الْقِبْلَةِ أَدَاءً لله تَعَالَى" baca="Ushallii fardash-Shubhi rak’ataini mustaqbilal qiblati (adaaan) [makmuuman / imaaman] lillâhi ta‘âlâ." arti="Saya (berniat) mengerjakan sholat fardhu Shubuh sebanyak dua raka’at dengan menghadap kiblat, (Sebagai) [makmum / imam], karena Allah Ta’ala" />
          <h3 className='mt-4 mb-1 font-bold'>Niat Shalat Dzuhur</h3>
          <Alquran ayat="اُصَلِّيْ فَرْضَ الظُّهْرِ أَرْبَعَ رَكَعاَتٍ مُسْتَقْبِلَ الْقِبْلَةِ أَدَاءً لله تَعَالَى" baca="Ushallii fardhazh-Zhuhri arba’a raka’aatin mustaqbilal qiblati (adaaan) [makmuuman / imaaman] lillâhi ta‘âlâ." arti="Saya (berniat) mengerjakan sholat fardhu Dzuhur sebanyak empat raka’at dengan menghadap kiblat, (Sebagai) [makmum / imam], karena Allah Ta’ala" />

          <h3 className='mt-4 mb-1 font-bold'>Niat Shalat Ashar</h3>
          <Alquran ayat="أُصَلِّى فَرْضَ العَصْرِأَرْبَعَ رَكَعاَتٍ مُسْتَقْبِلَ الْقِبْلَةِ أَدَاءً لله تَعَالَى" baca="Ushallii fardhal ‘Ashri arba’a raka’aatin mustaqbilal qiblati (adaaan) [makmuuman / imaaman] lillâhi ta‘âlâ." arti="Saya (berniat) mengerjakan sholat fardhu Ashar sebanyak empat raka’at dengan menghadap kiblat, (Sebagai) [makmum / imam], karena Allah Ta’ala" />

          <h3 className='mt-4 mb-1 font-bold'>Niat Shalat Maghrib</h3>
          <Alquran ayat="أُصَلِّى فَرْضَ المَغْرِبِ ثَلاَثَ رَكَعاَتٍ مُسْتَقْبِلَ الْقِبْلَةِ أَدَاءً لله تَعَالَ" baca="Ushallii fardhal Maghribi tsalaatsa raka’aatin mustaqbilal qiblati (adaaan) [makmuuman / imaaman] lillâhi ta‘âlâ." arti="Saya (berniat) mengerjakan sholat fardhu Maghrib sebanyak tiga raka’at dengan menghadap kiblat, (sebagai) [makmum / imam] karena Allah Ta’ala" />

          <h3 className='mt-4 mb-1 font-bold'>Niat Shalat Isya</h3>
          <Alquran ayat="أُصَلِّى فَرْضَ العِشَاء ِأَرْبَعَ رَكَعاَتٍ مُسْتَقْبِلَ الْقِبْلَةِ أَدَاءً لله تَعَالَى" baca="Ushallii fardhal Isyaa’i arba’a raka’aatin mustaqbilal qiblati (adaaan) [makmuuman / imaaman] lillâhi ta‘âlâ." arti="Saya (berniat) mengerjakan sholat fardhu Isya’ sebanyak empat raka’at dengan menghadap kiblat, (sebagai) [makmum / imam] karena Allah Ta’ala" />
        </div>
      </div>
    </>
  )
}
