const d = new Date();

export function getPray() {
  const prayer = [
    {
      'name': 'Subuh',
      'time': false
    },
    {
      'name': 'Dzuhur',
      'time': false
    },
    {
      'name': 'Ashar',
      'time': false
    },
    {
      'name': 'Maghrib',
      'time': false
    },
    {
      'name': 'Isya',
      'time': false
    }
  ];
  return prayer;
}

export function getTimePray() {
  let date = new Date(d);
  let hour = date.getHours();
  if ((hour <= 4 && hour >= 0) || hour > 20) {
    return 0;
  } else if (hour <= 12 && hour >= 9) {
    return 1;
  } else if (hour <= 16 && hour >= 15) {
    return 2;
  } else if (hour <= 19 && hour >= 17) {
    return 3;
  } else if (hour <= 20 && hour >= 19) {
    return 4;
  }
  return 0;
}

export function getFormattedDate(time) {
  // let dateString = (new Date()).toLocaleString();
  let date = new Date(d);
  time = time.split(':');

  date.setHours(time[0], time[1], time[2]);
  return date;
}

export function getNextPrayer(prayer) {
  // let dateString = new Date();
  let tanggal = new Date(d);

  const now = tanggal.getTime();
  var next;
  var nextIndex;
  var timeNextPrayer;

  prayer.map((e, i) => {
    var prayerTimeMilliseconds = new Date(e.time).getTime();
    let subTime = prayerTimeMilliseconds - now;
    if (subTime >= 1) {
      // console.log(this.msToTimeReadeble(subTime));
      // this.setState({ timeNextPrayer: this.msToTimeReadeble(subTime) })

      if (!next || (prayerTimeMilliseconds - now) < next) {
        next = prayerTimeMilliseconds - now
        // timeNextPrayer = msToTimeReadeble(next);
        timeNextPrayer = new Date(next + now);
        nextIndex = i
      }
    } else if (subTime < 1) {
      if (e.name === 'Subuh') {
        let prayerMiliseconds = new Date(e.time);
        prayerMiliseconds.setTime(prayerMiliseconds.getTime() + 86400000);
        let subTime2 = prayerMiliseconds - now;

        if (subTime2 >= 1) {
          // this.setState({ timeNextPrayer: this.msToTimeReadeble(subTime2) })

          if (!next || (prayerMiliseconds - now) < next) {
            next = prayerMiliseconds - now
            // timeNextPrayer = msToTimeReadeble(next);
            timeNextPrayer = new Date(next + now);
            nextIndex = i
          }
        }
      }
    }
    return false;
  })
  return [nextIndex, timeNextPrayer];
}

export function msToTimeReadeble(milliseconds) {
  //Get hours from milliseconds
  let res;
  var hours = milliseconds / (1000 * 60 * 60);
  var absoluteHours = Math.floor(hours);
  var h = absoluteHours > 9 ? absoluteHours : absoluteHours;
  res = h === 0 ? '' : h + ' Jam ';

  //Get remainder from hours and convert to minutes
  var minutes = (hours - absoluteHours) * 60;
  var absoluteMinutes = Math.floor(minutes);
  var m = absoluteMinutes > 9 ? absoluteMinutes : absoluteMinutes;
  res += m === 0 ? '' : m + ' Menit ';

  // //Get remainder from minutes and convert to seconds
  if (h === 0 && m <= 3) {
    var seconds = (minutes - absoluteMinutes) * 60;
    var absoluteSeconds = Math.floor(seconds);
    var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;
    res += s === '0' ? '' : s + ' Detik';
  }

  return res;
}
