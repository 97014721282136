import React from 'react'
// import logo from "../assets/images/logo-leughok.png";
import logo from "../assets/images/logo.png";
import SwitchMode from '../component/SwitchMode';


export default function Navbar() {
  return (
    <>
      <header
        className="fixed z-50 flex items-center justify-between w-full px-3 py-2 bg-primary xl:px-99 dark:bg-gray-800 drop-shadow-lg">
        <div className="font-bold text-white transition-all duration-200 logo hover:text-secondary">
          <a href="https://leughok.com"><img src={logo} width='200' alt='Logo Aplikasi'></img></a>
        </div>
        <SwitchMode />
      </header>
    </ >
  );
}
