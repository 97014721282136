export function toCapital(text) {
  let newText = "";
  if (typeof text == 'string') {
    newText = text.split(" ");

    for (let i = 0; i < newText.length; i++) {
      newText[i] = newText[i].toLowerCase();
      newText[i] = newText[i][0].toUpperCase() + newText[i].substr(1) + " ";
    }
  }

  return newText;
}

