import React from 'react'

export default function SwitchMode() {
  const [dark, setDark] = React.useState(localStorage.theme === 'dark' ? false : true)
  let gelap, terang;
  if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches) || !dark) {
    document.documentElement.classList.add('dark');
    document.body.classList.add('is-dark-mode');
    document.body.classList.remove('is-light-mode');
    gelap = 'hidden';
  } else {
    document.documentElement.classList.remove('dark');
    document.body.classList.add('is-light-mode');
    document.body.classList.remove('is-dark-mode');
    terang = 'hidden';
  }

  const setDarkMode = () => {
    setDark(!dark);
    if (dark) {
      document.querySelector('#theme-toggle-dark-icon').classList.add('hidden');
      document.querySelector('#theme-toggle-light-icon').classList.remove('hidden');
      localStorage.theme = 'dark';
    } else {
      document.querySelector('#theme-toggle-light-icon').classList.add('hidden');
      document.querySelector('#theme-toggle-dark-icon').classList.remove('hidden');
      localStorage.theme = 'light'
    }
  }

  return (
    <button id="theme-toggle" data-tooltip-target="tooltip-toggle" type="button" className="text-gray-200 dark:text-gray-400 hover:bg-gray-100 hover:text-gray-700 dark:hover:bg-gray-700 focus:outline-none rounded-lg text-sm p-2.5" onClick={() => setDarkMode()}>
      {/* Tombol Dark Icon */}
      <svg aria-hidden="true" id="theme-toggle-dark-icon" className={`${gelap} w-5 h-5`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path></svg>

      {/* Tombol Light Icon */}
      <svg aria-hidden="true" id="theme-toggle-light-icon" className={`${terang} w-5 h-5 text-white`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" fillRule="evenodd" clipRule="evenodd"></path></svg>
    </button >
  )

}
