import React from 'react'
import ImsakiyahPage from '../Layout/ImsakiyahVariable'
import { useParams } from 'react-router-dom'

// Hanya sebagai oper variable params
// Class sekarang sudah tidak bisa pake props.match, jadinya transfer pake functional component
export default function Main() {
  let { slug, prov } = useParams();
  if (slug === undefined) {
    slug = 'bireuen';
  }
  if (prov === undefined) {
    prov = '11';
  }

  return (
    <ImsakiyahPage slug={slug} prov={prov} />
  )
}
