import React, { useEffect, useState } from "react";
import TableImsakiyah from "../component/TableImsakiyah";
import SelectSearch from "react-select-search";
import 'react-select-search/style.css'
import { RootContext } from "./ImsakiyahVariable";
import { useNavigate } from "react-router-dom";
import { months } from "../utils/dateFormat";
import SuratLayout from "./SuratLayout";
import '../assets/css/SelectSearch.css';
import { toCapital } from "../utils/textManipulation";


export default function MainImsakiyah() {
  const navigate = useNavigate();
  const [bulan, setBulan] = useState(new Date().getMonth() + 1);
  const [tahun, setTahun] = useState(new Date().getFullYear());

  let handleKotaChangeMain;
  let handleImsakiyahChangeMain;
  let kotaSelection;
  let provSelection;

  const handleKotaChange = (kota, prov) => {
    handleKotaChangeMain(kota, prov);
    if (prov.value !== '11') {
      navigate(`/${kota.SlugKabupaten}/${prov.value}`);
    } else {
      navigate(`/${kota.SlugKabupaten}`);
    }
  }

  useEffect(() => {
    if (tahun >= 2023) {
      handleImsakiyahChangeMain(bulan, tahun);
    } else {
      setTahun(new Date().getFullYear())
      setBulan(12);
    }
  }, [bulan, tahun, handleImsakiyahChangeMain])


  const nextImsakiyah = () => {
    // If pertama hanya sebagai limit
    // API hanya memiliki tahun 2023
    if (bulan === 12 && tahun === new Date().getFullYear()) {
      return;
    } else if (bulan === 12 && tahun >= new Date().getFullYear()) {
      setBulan(1);
      setTahun(tahun + 1)
    } else {
      setBulan(bulan + 1);
    }
  }

  const previousImsakiyah = () => {
    // If pertama hanya sebagai limit
    // API hanya memiliki tahun 2023
    if (bulan === 1 && tahun === new Date().getFullYear()) {
      return;
    } else if (bulan === 1 && tahun >= new Date().getFullYear()) {
      setBulan(12);
      setTahun(tahun - 1)
    } else {
      setBulan(bulan - 1);
    }
  }

  return (
    <RootContext.Consumer>
      {
        state => {
          handleImsakiyahChangeMain = state.handleImsakiyahChange;
          const value = state.state;
          const handleProvChange = state.handleProvChange;
          handleKotaChangeMain = state.handleKotaChange;
          const kota = value.kotaSelected.name;
          provSelection = value.provSelect.value;
          kotaSelection = value.kotaSelected.value;
          let newKota = "";
          if (typeof kota == 'string' && kota !== undefined) {
            newKota = toCapital(kota);
          } else {
            newKota = toCapital(state.state.slug.replaceAll('-', ' '));
          }
          return (
            <div className="px-4 sm:px-14 md:px-16 lg:px-52 xl:px-64 pt-10 pb-20 bg-white lg:space-x-0 lg:pb-36 dark:text-white dark:bg-gray-900">
              <div className="relative mt-1 rounded-md w-full">
                <p className="font-bold text-sm lg:text-[17px] w-full text-left mb-3">Lihat Jadwal Shalat dan Imsakiyah untuk Wilayah Lain</p>
                <div className="md:grid md:grid-cols-2">
                  <div className="mb-3 flex justify-center">
                    <SelectSearch options={value.provinsi} name="provinsi" search placeholder="Pilih Provinsi..." onChange={(idx, data) => handleProvChange(data)} value={provSelection} />
                  </div>
                  <div className="flex justify-center">
                    <SelectSearch options={value.kota} name="kota" search placeholder="Pilih Kota..." onChange={(idx, data) => handleKotaChange(data, value.provSelect)} value={kotaSelection} />
                  </div>
                </div>
              </div>
              <div className="mt-[70px] mb-3 px-4 sm:px-14 md:px-16 lg:px-52dark:text-white dark:bg-gray-900">
                <h2 className="font-bold text-[20px] md:text-[23px] w-full text-center mt-4">
                  Jadwal Shalat dan Imsakiyah Hari Ini di {newKota}
                </h2>
              </div>

              <div id="data-table">
                <div className="flex px-3">
                  {/* Previous */}
                  <button type="button" onClick={() => { previousImsakiyah() }} className="text-primary hover:text-sky-600 dark:text-slate-400 dark:hover:text-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={30} fill="currentColor" ><path d="M360 224L272 224v-56c0-9.531-5.656-18.16-14.38-22C248.9 142.2 238.7 143.9 231.7 150.4l-96 88.75C130.8 243.7 128 250.1 128 256.8c.3125 7.781 2.875 13.25 7.844 17.75l96 87.25c7.031 6.406 17.19 8.031 25.88 4.188s14.28-12.44 14.28-21.94l-.002-56L360 288C373.3 288 384 277.3 384 264v-16C384 234.8 373.3 224 360 224zM256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464z" /></svg>
                  </button>
                  <div className="grow text-center">{`Bulan ${months[bulan - 1]} Tahun ${tahun}`}</div>
                  {/* Next */}
                  <button type="button" onClick={() => { nextImsakiyah() }} className="text-primary hover:text-sky-600 dark:text-slate-400 dark:hover:text-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={30} fill="currentColor"><path d="M280.2 150.2C273.1 143.8 262.1 142.2 254.3 146.1S239.1 158.5 239.1 167.1l.002 56L152 224C138.8 224 128 234.8 128 248v16C128 277.3 138.8 288 152 288L240 287.1v56c0 9.531 5.656 18.16 14.38 22c8.75 3.812 18.91 2.094 25.91-4.375l96-88.75C381.2 268.3 384 261.9 384 255.2c-.3125-7.781-2.875-13.25-7.844-17.75L280.2 150.2zM256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464z" /></svg>
                  </button>
                </div>
                <div className="mb-8">
                  <TableImsakiyah />
                  <p className="text-[11px]"><span className="text-red-600">*</span>Note: Jadwal shalat dan imsakiyah diambil dari data Kementerian Agama Republik Indonesia</p>
                </div>
                <SuratLayout />
              </div>
            </div>
          )
        }
      }
    </RootContext.Consumer>
  );
}


