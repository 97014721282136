import React from 'react'

export default function Alquran({ ayat, baca, arti }) {
  return (
    <div className="bg-slate-100 dark:bg-slate-800 p-[6px] sm:p-4">
      <p className='flex justify-end text-lg sm:text-2xl mb-[10px]'>{ayat}</p>
      <p className='flex justify-end italic text-[12px] sm:text-sm mb-3 text-justify'>{baca}</p>
      <p className='flex justify-start text-[12px] sm:text-sm'>Artinya: <i className='ml-2 text-justify'>"{arti}"</i></p>
    </div>
  )
}
