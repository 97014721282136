// import NavLink from "../Components/Navlink";
import React from "react";
import logo from '../assets/images/logo.png';
export default function LandingFooter() {
  const year = new Date().getFullYear();

  return (
    <footer className="py-10 text-white bg-primary dark:bg-gray-800 text-center md:text-left">
      <div className="px-5 xl:px-99 ">
        <a href='https://leughok.com' rel="noreferrer" target='_blank' className="inline-block">
          <img src={logo} width={250} alt="Logo Leughok" />
        </a>
        <p className="text-xs xl:text-justify font-extralight mt-3">
          &copy; {year} <a href='https://leughok.com' rel="noreferrer" target='_blank'>Leughok.com</a> - All Rights Reserved
        </p>
      </div>
    </footer>
  );
}
