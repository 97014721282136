import React from 'react'
import { Route, Routes } from 'react-router-dom';
import Imsakiyah from './pages/Imsakiyah';

export default function App() {
  document.title = 'Jadwal Shalat dan Imsakiyah | LEUGHOK.COM';
  return (
    <Routes>
      <Route path='/' element={<Imsakiyah slug={'bireuen'} prov={'11'} />} />
      <Route path='/:slug' element={<Imsakiyah />} />
      <Route path='/:slug/:prov' element={<Imsakiyah />} />
    </Routes>
  );
}
