import React from "react";
import { dateFormat } from "../utils/dateFormat";
import { RootContext } from "../Layout/ImsakiyahVariable";

export default function TableImsakiyah() {
  return (
    <RootContext.Consumer>
      {
        state => {
          const value = state.state;
          let date = value.date;
          const day = '0' + date.getDate();
          const month = '0' + (date.getMonth() + 1);
          const year = date.getFullYear();
          const tgl = `${year}-${(month).slice(-2)}-${day.slice(-2)}`;
          return (
            <div className="flex flex-col justify-center" >
              <div className="sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <table className="min-w-full table-fixed dark:text-gray-400">
                      <thead className="border-b text-xs uppercase bg-primary text-gray-100 dark:bg-gray-800 dark:text-gray-200">
                        <tr className="text-xs sm:font-bold sm:text-sm">
                          <th scope="col-5" className="text-center py-4">
                            TANGGAL
                          </th>
                          <th scope="col" className="text-center py-4">
                            IMSAK
                          </th>
                          <th scope="col" className="text-center py-4">
                            SUBUH
                          </th>
                          <th scope="col" className="text-center py-4">
                            DZUHUR
                          </th>
                          <th scope="col" className="text-center py-4">
                            ASHAR
                          </th>
                          <th scope="col" className="text-center py-4">
                            MAGHRIB
                          </th>
                          <th scope="col" className="text-center py-4">
                            ISYA
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-center text-xs sm:text-sm">
                        {value.jadwalImsakiyah && value.jadwalImsakiyah.map((jadwal, index) => (
                          <tr className={tgl !== jadwal.Tanggal ? "odd:bg-slate-200  even:bg-slate-100 hover:bg-slate-300 border-b text-gray-900 dark:odd:bg-gray-900 dark:even:bg-gray-800 dark:hover:bg-gray-700 dark:text-white dark:border-gray-600" : "bg-[#EF7A04] dark:bg-[#EF7A04] border-b dark:border-gray-700 text-white hover:bg-[#db6f02] dark:hover:bg-[#db6f02]"} key={index}>
                            <td className="font-light px-3 py-2 sm:text-left text-center w-32 sm:w-52">
                              {dateFormat(jadwal.Tanggal)}
                            </td>
                            <td className="font-light py-2 whitespace-nowrap px-2">
                              {jadwal.Imsak.slice(0, 5)}
                            </td>
                            <td className="font-light py-2 whitespace-nowrap px-2">
                              {jadwal.Subuh.slice(0, 5)}
                            </td>
                            <td className="font-light py-2 whitespace-nowrap px-2">
                              {jadwal.Zuhur.slice(0, 5)}
                            </td>
                            <td className="font-light py-2 whitespace-nowrap px-2">
                              {jadwal.Asar.slice(0, 5)}
                            </td>
                            <td className="font-light py-2 whitespace-nowrap px-2">
                              {jadwal.Magrib.slice(0, 5)}
                            </td>
                            <td className=" font-light py-2 whitespace-nowrap px-2">
                              {jadwal.Isya.slice(0, 5)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      }
    </RootContext.Consumer>
  );
}

