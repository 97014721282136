import React, { Fragment, useEffect, useState } from "react";
import ImBg from "../assets/images/background.jpeg";
import { RootContext } from "./ImsakiyahVariable";
import { msToTimeReadeble } from "../utils/prayer";
import { toCapital } from "../utils/textManipulation";
import { months } from "../utils/dateFormat";

export default function LandingHeader({ time }) {
  const [leftTime, setLeftTime] = useState();
  let now = new Date().getTime();
  let distance = time - now;

  useEffect(() => {
    const interval = setInterval(() => {
      distance -= 1000;

      if (distance < 0 && distance > -2000) {
        clearInterval(interval);
        window.location.reload(false)
      } else if (distance < -2000) {
        clearInterval(interval);
      } else {
        setLeftTime(msToTimeReadeble(distance));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [distance])

  return (
    <RootContext.Consumer>
      {
        state => {
          let tanggal = state.state.date;
          let newDate = new Date(tanggal);
          let value = state.state;
          let kota = value.kotaSelected.name;
          let newKota = kota;
          if (kota !== undefined) {
            newKota = toCapital(kota);
          } else {
            newKota = toCapital(state.state.slug.replaceAll('-', ' '));
          }
          return (
            <Fragment>
              <div
                className={`flex flex-col relative justify-center h-[85vh] px-5 font-bold text-center bg-no-repeat bg-cover md:px-0 md:text-6xl header-wrapper bg-inherit`}
                style={{
                  backgroundImage: `url('${ImBg}')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  overflow: "hidden",
                }}>
                <div className="absolute inset-0 bg-black dark:opacity-70 opacity-[0.65]" />
                <div className="relative mt-10 text-center heading-wrapper">
                  <h1 className="text-2xl md:text-3xl text-white">
                    Jadwal Shalat dan Imsakiyah
                  </h1>
                  <p className="text-sm md:text-base font-normal text-white">
                    Hari Ini, {newDate.getDate()} {months[newDate.getMonth()]} {newDate.getFullYear()}
                  </p>
                  <p className="text-sm md:text-base font-normal text-white">
                    <span id="countdown">{
                      leftTime}</span> Menuju Waktu Shalat <span id="shalat">{value.prayer[value.nextPrayer].name}</span> di <span id="daerah">{newKota}</span>
                  </p>
                </div>
              </div>

              <div className="grid">
                <div className="bg-primary py-[10px] px-3 dark:bg-slate-900 dark:text-white grid grid-cols-6 gap-6 text-white font-bold text-xs md:text-[16px]">
                  <div className="mx-auto">
                    <div>
                      <h2 className=" order-1">IMSAK</h2>
                    </div>
                  </div>
                  <div className="mx-auto">
                    <h2 className="">SUBUH</h2>
                  </div>
                  <div className="mx-auto">
                    <h2 className="">DZUHUR</h2>
                  </div>
                  <div className="mx-auto">
                    <h2 className="">ASHAR</h2>
                  </div>
                  <div className="mx-auto">
                    <h2 className="">MAGHRIB</h2>
                  </div>
                  <div className="mx-auto">
                    <h2 className="">ISYA</h2>
                  </div>
                </div>
                <div className="bg-white py-[10px] border-b-2 dark:border-slate-700 dark:bg-slate-700 px-3 text-black grid grid-cols-6 gap-6 text-xs md:text-[16px]">
                  <div className="mx-auto">
                    <h3 className="dark:text-white">{String(value.imsakiyah.Imsak).slice(0, 5)}</h3>
                  </div>
                  <div className="mx-auto">
                    <h3 className="dark:text-white">{String(value.imsakiyah.Subuh).slice(0, 5)}</h3>
                  </div>
                  <div className="mx-auto">
                    <h3 className="dark:text-white">{String(value.imsakiyah.Zuhur).slice(0, 5)}</h3>
                  </div>
                  <div className="mx-auto">
                    <h3 className="dark:text-white">{String(value.imsakiyah.Asar).slice(0, 5)}</h3>
                  </div>
                  <div className="mx-auto">
                    <h3 className="dark:text-white">{String(value.imsakiyah.Magrib).slice(0, 5)}</h3>
                  </div>
                  <div className="mx-auto">
                    <h3 className="dark:text-white">{String(value.imsakiyah.Isya).slice(0, 5)}</h3>
                  </div>
                </div>
              </div>
            </Fragment >
          )
        }
      }
    </RootContext.Consumer>
  );
}

